const hideTextarea = textarea => {
  textarea.style.height = textarea.offsetHeight + "px";
  textarea.classList.remove("inspection-card__textarea--show");
  textarea.classList.remove("inspection-card__textarea--show-transitioned");
  setTimeout(function() {
    textarea.style.height = 0;
  });
  textarea.addEventListener(
    "transitionend",
    function hideTextarea() {
      textarea.classList.add("inspection-card__textarea--hidden");
      textarea.removeEventListener("transitionend", hideTextarea, false);
    },
    false
  );
};

const showTextarea = textarea => {
  textarea.classList.remove("inspection-card__textarea--hidden");
  setTimeout(function() {
    textarea.classList.add("inspection-card__textarea--show");
    textarea.style.height = 84 + "px";
    textarea.querySelector("textarea").focus();
  });
  textarea.addEventListener(
    "transitionend",
    function showTextarea() {
      textarea.style.height = null;
      textarea.classList.add("inspection-card__textarea--show-transitioned");
      textarea.removeEventListener("transitionend", showTextarea, false);
    },
    false
  );
};

export const toggleRenovationNote = () => {
  const handleCheck = textarea => {
    showTextarea(textarea);
  };

  const handleUncheck = textarea => {
    hideTextarea(textarea);
  };

  const requiresRenovation = document.querySelector("[data-requires-renovation]");

  if (requiresRenovation) {
    const checkbox = requiresRenovation.querySelector("input[type='checkbox']");

    const textarea = requiresRenovation.parentNode.querySelector(
      "[data-requires-renovation-textarea]"
    );

    if (checkbox.checked) {
      handleCheck(textarea);
    }

    checkbox.addEventListener("change", () => {
      if (checkbox.checked) {
        handleCheck(textarea);
      } else {
        handleUncheck(textarea);
      }
    });
  }
};


export const toggleInspectionRecheck = () => {
  const handleCheck = textarea => {
    showTextarea(textarea);
  };

  const handleUncheck = textarea => {
    hideTextarea(textarea);
  };

  const inspectionRecheck = document.querySelector("[data-inspection-recheck]");

  if (inspectionRecheck) {
    const checkbox = inspectionRecheck.querySelector("input[type='checkbox']");

    const textarea = inspectionRecheck.parentNode.querySelector(
      "[data-inspection-textarea]"
    );

    const submit = document.querySelector("[data-inspection-button]");

    if (checkbox.checked) {
      handleCheck(textarea);
    }

    checkbox.addEventListener("change", () => {
      if (checkbox.checked) {
        handleCheck(textarea);

        submit.innerHTML = submit.dataset.reject;
        submit.classList.remove("button--green");
      } else {
        handleUncheck(textarea);
        submit.innerHTML = submit.dataset.archive;
        submit.classList.add("button--green");
      }
    });
  }
};

export const toggleInspectionComment = () => {
  const handleCheckConditionWithoutNote = textarea => {
    hideTextarea(textarea);
  };

  const handleCheckConditionWithNote = textarea => {
    showTextarea(textarea);
  };

  const inspectionRatings = document.querySelectorAll(
    "[data-inspection-rating]"
  );

  Array.from(inspectionRatings).forEach(inspectionRating => {
    const radioInputs = inspectionRating.querySelectorAll(
      "input[type='radio']"
    );
    const textarea = inspectionRating.parentNode.querySelector(
      "[data-inspection-textarea]"
    );
    Array.from(radioInputs).forEach(radio => {
      let checkHandler;

      if (radio.value === "bad") {
        checkHandler = () => handleCheckConditionWithNote(textarea);
      } else {
        checkHandler = () => handleCheckConditionWithoutNote(textarea);
      }

      if (radio.checked) {
        checkHandler();
      }

      radio.addEventListener("change", () => {
        checkHandler();
        const parentCard = radio.closest(".inspection-card");
        if (parentCard.classList.contains("inspection-card--error")) {
          parentCard.classList.remove("inspection-card--error");
        }
      });
    });
  });
};
