const taskEdit = () => {
  const advancedMaintenanceCheckbox = document.getElementById("task_advanced_maintenance")
  const cleaningRequestCheckbox = document.getElementById("task_cleaning_request")

  if (advancedMaintenanceCheckbox) {
    prefillSpecialTaskInfo(advancedMaintenanceCheckbox, "advanced_maintenance")
  }
  if (cleaningRequestCheckbox) {
    prefillSpecialTaskInfo(cleaningRequestCheckbox, "cleaning_request")
  }
};

function prefillSpecialTaskInfo(checkBox, taskType) {
  const locationOther = document.querySelector("[data-fault-location-other]");
  const description_textarea = document.getElementById("task_description")
  const location_selection = document.getElementById("task_location_id")
  const other_location = document.getElementById("task_other_location")
  const all_location_options = Array.from(location_selection.options)
  const other_location_option = all_location_options.find(option=> option.dataset.humanizedValue==='other').value
  const old_description = description_textarea.value
  const old_location = location_selection.value
  const old_other_location = other_location.value
  const old_other_location_hidden = locationOther.classList.contains("hidden")

  checkBox.addEventListener("change", event => {
    if (event.target.checked) {
      location_selection.value = other_location_option
      if (locationOther.classList.contains("hidden")) {
        locationOther.classList.remove("hidden")
      }
      other_location.disabled = false
      if (taskType === "advanced_maintenance") {
        description_textarea.value = description_textarea.dataset.advancedMaintenanceDescription
        other_location.value = "Koko huoneisto"
      } else if (taskType === "cleaning_request") {
        description_textarea.value = description_textarea.dataset.cleaningRequestDescription
        other_location.value = "Yhteiset tilat"
      }
      description_textarea.rows = 20
    } 
    else {
      description_textarea.value = old_description
      description_textarea.rows = 2
      location_selection.value = old_location
      other_location.value = old_other_location
      if (old_other_location_hidden === true) {
        other_location.disabled = true
        locationOther.classList.add("hidden")
      }
    }
  })
}

export default taskEdit;
