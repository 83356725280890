const paneToggle = () => {
  const paneHeaders = document.querySelectorAll("[data-pane-header]");

  if (paneHeaders) {
    Array.from(paneHeaders).forEach(header => {
      header.addEventListener("click", event => {
        const pane = event.target.closest("[data-pane]");
        const panes = document.querySelectorAll("[data-pane]");
        for (var i = 0; i < panes.length; i++) {
          panes[i].classList.remove("pane--active");
        }
        pane.classList.add("pane--active");
      });
    });
  }
};

export default paneToggle;
