const closeNotice = () => {
  const noticeCloses = document.querySelectorAll("[data-notice-close]");

  Array.from(noticeCloses).forEach(noticeClose => {
    if (noticeClose) {
      noticeClose.addEventListener("click", event => {
        const notice = noticeClose.closest(".notice");
        notice.style.maxHeight = notice.offsetHeight + "px";
        notice.classList.add("notice--hide");
        setTimeout(function() {
          notice.style.maxHeight = null;
        });
        notice.addEventListener(
          "transitionend",
          function(event) {
            if (event.target === notice) {
              notice.style.display = "none";
            }
          },
          false
        );
      });
    }
  });
};

export default closeNotice;
