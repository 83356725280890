// import { removeFields } from "./removeFields"
import { initStairSelection } from "./faultReportCreate.js"
export class addFields {
  // This executes when the function is instantiated.
  constructor() {
    this.links = document.querySelectorAll('.add_apartment_fields')
    this.iterateLinks()
  }

  iterateLinks() {

    // If there are no links on the page, stop the function from executing.
    if (this.links.length === 0) return
    // Loop over each link on the page. A page could have multiple nested forms.
    this.links.forEach(link => {
      link.addEventListener('click', e => {
        this.handleClick(link, e)
      })
    })
  }

  handleClick(link, e) {
    // Stop the function from executing if a link or event were not passed into the function.
    if (!link || !e) return
    // Prevent the browser from following the URL.
    e.preventDefault()

    // nearest_selection = the closest apartment selection field (= last selection field before we add another below)
    // const nearest_selection = [...document.querySelectorAll("[data-apartment-selector]")].pop()
    const previous_selections = [...document.querySelectorAll("[data-apartment-selector]")].flatMap(select => 
      Array.from(select.selectedOptions).map(option => option.value)
    )
    // console.log("previous selection", previous_selections)
    
    let newFields = link.dataset.fields
    // Add the new markup to the form if there are fields to add.
    newFields ? link.insertAdjacentHTML('beforebegin', newFields) : null

    // new_selection = the new apartment selection field we just add
    const new_selection = [...document.querySelectorAll("[data-apartment-selector]")].pop()
    const building = document.querySelector("[data-building-selector]");
    // TODO Tien if needed it to filter to related apartments, try regex
    // console.log(`/^${nearest_selection.options[nearest_selection.selectedIndex].label.split("/")[0]}$/`)
    if (building) {
      const buildingId = building.value
      // console.log("it reaches here")
      Array.from(new_selection.options).forEach(node => {
        if (
          (node.dataset.buildingId && node.dataset.buildingId !== buildingId) || previous_selections.includes(node.value)
          // node.label.split("/")[0].match(/^${nearest_selection.options[nearest_selection.selectedIndex].label.split("/")[0]}$/) == null
        ) {
          new_selection.removeChild(node);
        }
      });
    }
    // console.log("before initStairSelection")
    initStairSelection(new_selection)
    // disable button again
    document.getElementsByClassName("add_apartment_fields")[0].disabled = true
    // new removeFields() 
  }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new addFields())