import Turbolinks from "turbolinks";
import Rails from "rails-ujs";
import "nodelist-foreach-polyfill";
import "element-closest";
import "cocoon-js";
import * as Pikaday from "pikaday";
import debounce from "lodash.debounce";
import siderbarToggle from "./sidebar.js";
import { areaPickerToggle } from "./filterPicker.js";
import closeNotice from "./notices.js";
import {
	toggleInspectionComment,
	toggleInspectionRecheck,
	toggleRenovationNote,
} from "./inspectionCard.js";
import initSegmentedControl from "./segmentedControl.js";
import SimpleBar from "simplebar";
import paneToggle from "./panes.js";
import taskActions from "./taskActions.js";
import taskEdit from "./taskEdit.js";
import handleTaskCreate from "./faultReportCreate.js";
import initList from "./apartmentSearch.js";
import baguetteBox from "baguettebox.js";
import previewImage from "./imagePreview.js";
import initSidepanels from "./sidepanel.js";
import initSearchFilter from "./searchFilter";
import initCheckboxFilterList from "./checkboxFilterList";
import initMultiSelectInput from "./multiSelectInput";
import relatedTasksToggle from "./relatedTasks";
import linkedTaskToggles from "./linkedTasks";

import { uppySingleFileUpload, uppyMultipleFileUpload } from "./uppyFileUpload";
import updateTags from "./tags";

import autosize from "autosize";

require('./addFields')
Turbolinks.start();
Rails.start();

document.addEventListener("turbolinks:load", (event) => {
	const textAreas = document.querySelectorAll("textarea");
	Array.from(textAreas).forEach((textArea) => {
		textArea.addEventListener("focus", function () {
			autosize(textArea);
		});
	});

  Array.prototype.forEach.call(
    document.querySelectorAll("[data-sb]"),
    el => new SimpleBar(el)
  );

	if (document.getElementsByClassName("uppy-upload-file").length != 0) {
		Array.from(document.querySelectorAll(".uppy-upload-file")).forEach(
			(fileInput) => {
				uppyMultipleFileUpload(fileInput, "s3");
			}
		);
	} else {
		Array.from(document.querySelectorAll(".local-uppy-upload-file")).forEach(
			(fileInput) => {
				uppyMultipleFileUpload(fileInput, "local");
			}
		);
	}

	const tagInputs = document.querySelectorAll("[data-role*='tag-checkbox']");

	const notice = document.querySelector(".save-notice");

	Array.from(tagInputs).forEach((tagCheckbox) => {
		tagCheckbox.addEventListener("change", function () {
			updateTags(tagCheckbox);
			if (tagCheckbox.checked === true) {
				notice.classList.add("isvisible");
				setTimeout(() => {
					notice.classList.remove("isvisible");
				}, 1000);
			}
		});
	});

	siderbarToggle();
  areaPickerToggle();
  closeNotice();
  toggleInspectionComment();
  toggleInspectionRecheck();
  toggleRenovationNote();
  initSegmentedControl();
  paneToggle();
  taskActions();
  taskEdit();
  handleTaskCreate();
  initList();
  initSidepanels();
  initSearchFilter();
  initCheckboxFilterList();
  initMultiSelectInput();
  relatedTasksToggle();
	linkedTaskToggles();

	window.addEventListener("resize", debounce(initSegmentedControl));
	window.previewImage = previewImage;

	const datepickers = Array.from(document.querySelectorAll("[data-pikaday]"));
	datepickers.forEach((element) => {
		new Pikaday({
			field: element,
			disableWeekends: element.dataset.disableWeekends === "true",
			firstDay: 1,
			minDate: new Date(element.dataset.minDate || null),
		});
	});
	baguetteBox.run("[data-image-lightbox]");

	const specialTaskCheckBoxes = document.querySelectorAll('input[type="checkbox"][data-special-task-type]');

	specialTaskCheckBoxes.forEach(function (checkbox) {
		checkbox.addEventListener("change", function () {
			// Uncheck the other checkbox when one is checked
			specialTaskCheckBoxes.forEach(function (otherCheckbox) {
				if (otherCheckbox !== checkbox) {
					otherCheckbox.checked = false;
				}
			});
		});
	});
});
