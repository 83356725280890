const updateTags = (el) => {
	let form = formFor(el);
	const headers = {
		"X-Requested-With": "XMLHttpRequest",
		"X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
		Accept: "application/json",
		"Content-Type": "application/json",
	};

	const filter = Array.prototype.filter;
	let result = document.querySelectorAll("[data-role*='tag-checkbox']");
	let filtered = filter.call(result, function (node) {
		return node.checked;
	});

	if (form.action.includes("inspection_cards/moving_outs")) {
		let groupedByTags = filtered.reduce((r, a) => {
			r[a.dataset.taskId] = [...r[a.dataset.taskId] || [], ...a.value];
			return r;
		 }, {});
		let itemsAttributes = {}

		Object.keys(groupedByTags).forEach(function (id, index) { 
			itemsAttributes[index] = {id: id, tag_ids: groupedByTags[id]}
		})

		return fetch(form.action, {
			method: "PUT",
			headers: headers,
			credentials: "same-origin",
			body: JSON.stringify({draft: true, inspection_card: {items_attributes: itemsAttributes}}),
		}).then((response) => {
			return response.json();
		});
	} else {
		let tagIds = filtered.map((item) => item.value);
		return fetch(form.action, {
			method: "PUT",
			headers: headers,
			credentials: "same-origin",
			body: JSON.stringify({ task: { tag_ids: tagIds } }),
		}).then((response) => {
			return response.json();
		});
	}

};

const formFor = (element) => {
	return element.closest("form");
};

export default updateTags;
