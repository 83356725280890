require('es6-promise/auto')
require('whatwg-fetch')
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import Dashboard from '@uppy/dashboard'
import ProgressBar from '@uppy/progress-bar'
import Informer from '@uppy/informer'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import FileInput from '@uppy/file-input'
import AwsS3 from '@uppy/aws-s3'

export function uppyMultipleFileUpload(fileInput, server) {
  var uppyTarget = fileInput.parentNode
  var formGroup  = fileInput.closest("[data-photo-upload]")

  var fieldNameTemplate = fileInput.name

  var uppy = fileUpload(fileInput, server)

  var openButton = formGroup.querySelector("[data-photo-upload-button]")
  openButton.addEventListener("click", (e) => {
    e.preventDefault()
  })

  uppy
    .use(Dashboard, {
      target: uppyTarget,
      trigger: openButton,
      inline: false,
      height: 300,
      replaceTargetContent: true,
      closeModalOnClickOutside: true,
      closeAfterFinish: true
    })

  var index = new Date().getTime()

  uppy.on('upload-success', function (file, response) {
    var uploadedPhotosLabel = formGroup.querySelector("[data-photo-upload-label]")
    var noPhotoLabel = uploadedPhotosLabel.querySelector('[data-no-photo-label]')

    var hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = fieldNameTemplate.replace(/\[index\]/, `[${index}]`).replace(/\[\]/, '')
    hiddenField.value = uploadedFileData(file, response, fileInput, server)

    var fileLabel = document.createElement('div')
    fileLabel.classList.add("uploaded-file")
    var domString = `<span>${file.name}</span>&nbsp;<a href="" data-revome-uploaded-file=true class="photo-remove">Poista kuva</a>`;
    fileLabel.innerHTML = domString;
    fileLabel.appendChild(hiddenField)

    index += 1

    fileLabel.addEventListener("click", (e) => {
      e.preventDefault()
      if (!e.target.dataset.revomeUploadedFile) {
        return
      }
      e.currentTarget.remove()
      toggleNoPhotoLable(noPhotoLabel)
      uppy.removeFile(file.id)
    })

    uploadedPhotosLabel.appendChild(fileLabel)
    toggleNoPhotoLable(noPhotoLabel)
  })

  uppy.on('error', error => {
    // Create a new div element
    var errorDiv = document.createElement('div');

    // Set its text content to the error message
    errorDiv.textContent = 'Uppy error: ' + error.message;

    // Append the new div to the parent of the .uppy-Dashboard-files element
    var dashboardFiles = document.getElementsByClassName('uppy-Dashboard-files')[0];
    dashboardFiles.parentNode.appendChild(errorDiv);
  })
}

function toggleNoPhotoLable(label, index) {
  var imageCnt = label.closest('[data-photo-upload]').querySelectorAll('.uploaded-file').length
  if (imageCnt > 0) {
    label.style.display = "none";
    return
  }

  label.style.display = "block";
}

function fileUpload(fileInput, server) {
  var uppy = new Uppy({
      id: fileInput.id,
      autoProceed: false,
      restrictions: {
        allowedFileTypes: fileInput.accept.split(','),
      }
    })
  if (server == "s3") {
    uppy.use(AwsS3, {
      companionUrl: '/', // will call Shrine's presign endpoint mounted on `/s3/params`
    })
  } else {
    uppy.use(XHRUpload, {
      endpoint: '/upload', // will call Shrine's upload endpoint mounted on `/upload`
    })
  }

  return uppy
}

function uploadedFileData(file, response, fileInput, server) {
  // construct uploaded file data in the format that Shrine expects
  if (server == "s3") {
    return JSON.stringify({
      id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size:      file.size,
        filename:  file.name,
        mime_type: file.type,
      }
    })
  } else {
    return JSON.stringify(response.body)
  }
}



