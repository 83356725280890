const initializePickerToggle = (picker, toggle) => {
  const linkHeightInRems = 2;

  if (picker && toggle) {
    let pickerOpen = false;
    toggle.addEventListener('click', event => {
      event.stopPropagation();
      const optionsList = picker.querySelector('.filter-nav');
      const listHeight =
        optionsList.childElementCount * linkHeightInRems + 'rem';
      if (pickerOpen) {
        picker.classList.remove('filter-picker--active', 'filter-picker--open');
        optionsList.style.height = 0;
        pickerOpen = false;
      } else {
        picker.classList.add('filter-picker--active');
        optionsList.style.height = listHeight;
        pickerOpen = true;
        optionsList.addEventListener('transitionend', function(event) {
          if (pickerOpen) {
            picker.classList.add('filter-picker--open');
          }
        });
      }
    });
  }
};

const initializePickerSelect = (picker, toggle) => {
  if (picker && toggle) {
    let pickerOpen = false;
    toggle.addEventListener('change', event => {
      window.location = event.target.value;
    });
  }
};

export const areaPickerToggle = () => {
  const areaPicker = document.querySelector('[data-area-picker]');
  const areaToggle = document.querySelector('[data-area-toggle]');

  initializePickerToggle(areaPicker, areaToggle);
};
