import $ from "jquery";
import { uppySingleFileUpload, uppyMultipleFileUpload } from "./uppyFileUpload";

const bindRemoveTask = function() {
  const removeTasks = document.querySelectorAll(
    "[data-fault-report-remove-task]"
  );

  Array.from(removeTasks).forEach(button => {
    button.addEventListener("click", event => {
      bindLocationSelect();
      bindMasterKeyCheckboxes();
      bindSpecialTaskCheckboxes();
    });
  });
};

const bindLocationSelect = function() {
  const locationSelect = document.querySelectorAll(
    "[data-fault-location-select]"
  );

  Array.from(locationSelect).forEach(select => {
    const parent = select.closest("[data-fault-location]");
    if (parent) {
      const locationOther = parent.querySelector("[data-fault-location-other]");
      select.addEventListener("change", event => {
        if (
          event.target.value.length > 0 &&
          event.target.options[event.target.selectedIndex].dataset
            .humanizedValue !== "other"
        ) {
          locationOther.classList.add("hidden");
          Array.from(locationOther.getElementsByTagName("input")).forEach(
            input => {
              input.disabled = true;
            }
          );
        } else {
          locationOther.classList.remove("hidden");
          Array.from(locationOther.getElementsByTagName("input")).forEach(
            input => {
              input.disabled = false;
            }
          );
        }
      });
    }
  });
};

const bindMasterKeyCheckboxes = function() {
  const masterKeyCheckboxes = document.querySelectorAll(
    "[data-master-key-check]"
  );
  Array.from(masterKeyCheckboxes).forEach(checkbox => {
    const parent = checkbox.closest("[data-master-key]");
    const masterKeyDate = parent.querySelector("[data-master-key-date]");
    checkbox.addEventListener("change", event => {
      if (event.target.checked) {
        masterKeyDate.querySelector("input").value = null;
        masterKeyDate.classList.add("hidden");
      } else {
        masterKeyDate.classList.remove("hidden");
      }
    });
  });
};

const bindSpecialTaskCheckboxes = function() {
  const advancedMaintenanceCheckbox = document.getElementById("fault_report_advanced_maintenance")
  const cleaningRequestCheckbox = document.getElementById("fault_report_cleaning_request")
  
  if (advancedMaintenanceCheckbox) {
    prefillSpecialTaskInfo(advancedMaintenanceCheckbox, "advanced_maintenance")
  }
  if (cleaningRequestCheckbox) {
    prefillSpecialTaskInfo(cleaningRequestCheckbox, "cleaning_request")
  }
};

function prefillSpecialTaskInfo(checkBox, taskType) {
  checkBox.addEventListener("change", event => {
    const faultReportAddTask = document.getElementById("fault_report_new_task")
    const locationOther = document.querySelector("[data-fault-location-other]");
    const description_textarea = document.getElementById("fault_report_tasks_attributes_0_description")
    const location_selection = document.getElementById("fault_report_tasks_attributes_0_location_id")
    const other_location = document.getElementById("fault_report_tasks_attributes_0_other_location")
    const all_location_options = Array.from(location_selection.options)
    const other_location_option = all_location_options.find(option=> option.dataset.humanizedValue==='other').value
    const bedroom_location_option = all_location_options.find(option=> option.dataset.humanizedValue==='bedroom').value
    
    if (event.target.checked) {
      location_selection.value = other_location_option
      if (locationOther.classList.contains("hidden")) {
        locationOther.classList.remove("hidden")
        other_location.disabled = false
      }
      if (taskType === "advanced_maintenance") {
        description_textarea.value = description_textarea.dataset.advancedMaintenanceDescription
        other_location.value = "Koko huoneisto"
      } else if (taskType === "cleaning_request") {
        description_textarea.value = description_textarea.dataset.cleaningRequestDescription
        other_location.value = "Yhteiset tilat"
      }
      description_textarea.rows = 20
      faultReportAddTask.classList.add("hidden")
    } 
    else {
      if (description_textarea.value != "") {
        description_textarea.value = ""
        description_textarea.rows = 2
      }
      if (location_selection.value === other_location_option) {
        location_selection.value = bedroom_location_option
        other_location.value = ""
        locationOther.classList.add("hidden")
        other_location.disabled = true
      }
      if (faultReportAddTask.classList.contains("hidden")) {
        faultReportAddTask.classList.remove("hidden")
      }
    }
  })
}

const bindBuildingSelect = function() {
  const buildingSelect = document.querySelector("[data-building-selector]");
  const apartmentSelect = document.querySelector("[data-apartment-selector]");
  const allApartmentSelect = document.querySelector("[data-all-apartments-selector]");

  if (buildingSelect) {
    let initialOptions = [];
    if (allApartmentSelect) {
      // meaning we are on fault create page and not edit task page
      apartmentSelect.disabled = true;
      allApartmentSelect.disabled = true;
    }
    Array.from(apartmentSelect.children).forEach(node => {
      const child = node.cloneNode(true);
      initialOptions.push(child);
    });
    buildingSelect.addEventListener("change", event => {
      apartmentSelect.innerHTML = "";
      Array.from(initialOptions).forEach(node => {
        apartmentSelect.appendChild(node);
      });
      if (event.target.value === "") {
        [...document.querySelectorAll("[data-apartment-selector]")].filter(element => element !== apartmentSelect).forEach(element => element.parentElement.remove());
        apartmentSelect.disabled = true;
        if (allApartmentSelect) {
          allApartmentSelect.disabled = true;
        }
      } else {
        [...document.querySelectorAll("[data-apartment-selector]")].filter(element => element !== apartmentSelect).forEach(element => element.parentElement.remove());
        apartmentSelect.disabled = false;
        if (allApartmentSelect) {
          allApartmentSelect.disabled = false;
          allApartmentSelect.checked = false;
        }
        const buildingId = buildingSelect.value;
        Array.from(apartmentSelect.options).forEach(node => {
          if (
            node.dataset.buildingId &&
            node.dataset.buildingId !== buildingId
          ) {
            apartmentSelect.removeChild(node);
          }
        });
        initStairSelection(apartmentSelect)
      }
    });
  }
};

const initNewTaskImageUpload = function() {
  $('.task-list').on('cocoon:after-insert', function(e, newTask) {
    if (document.getElementsByClassName("uppy-upload-file").length != 0) {
      uppyMultipleFileUpload(newTask[0].querySelector('.uppy-upload-file'), "s3")
    } else {
      uppyMultipleFileUpload(newTask[0].querySelector('.local-uppy-upload-file'), "local")
    }
  });
}

const toggleAllApartmentsSelection = function(disabled) {
  [...document.querySelectorAll("[data-apartment-selector]")].forEach(element => {
    element.disabled = disabled;
  });
  document.getElementsByClassName("add_apartment_fields")[0].disabled = disabled;
}

const bindAllApartmentsSelection = function() {
  const allApartmentSelect = document.getElementById("fault_report_all_apartments_selected")
  if (allApartmentSelect) {
    allApartmentSelect.addEventListener('change', function() {
      if (this.checked) {
        toggleAllApartmentsSelection(true);
      } else {
        toggleAllApartmentsSelection(false);
      }
    });
  }
}

export const initStairSelection = function(apartmentSelect) {
  let stairs = []
  Array.from(apartmentSelect.options).slice(1).filter(option => {
    stairs.push(option.dataset.stair) 
  });
  stairs = [...new Set(stairs.filter(stair => stair !== undefined))];
  // console.log("stairs", stairs)

  bindStairSelection(apartmentSelect, stairs)
}

const bindStairSelection = function(apartmentSelect, stairs) {
  const buildingSelect = document.querySelector("[data-building-selector]");
  let positionToInsert = 0;

  stairs.forEach(stair => {
    positionToInsert += 1
    var stairNode = apartmentSelect.options[0].cloneNode(true);
    stairNode.label = stair + "-rapun kaikki asunnot" ;
    stairNode.value = stair;
    apartmentSelect.insertBefore(stairNode, apartmentSelect.children[positionToInsert]);
  });

  apartmentSelect.addEventListener("change", event => {
    // console.log("apartmentSelect changed", event.target.value)
    if (Array.from(event.target.classList).some(className => className.includes('hidden-stair-selection'))) {
      let className = Array.from(event.target.classList).find(className => className.includes('hidden-stair-selection'));
      let stair = className.split("-")[3];
      // console.log("removing stair selection", stair)
      let hiddenDiv = document.getElementById(`hidden-stair-selection-${stair}`);
      if (hiddenDiv) {
        // console.log("removing hiddenDiv,", stair)
        hiddenDiv.remove();
        event.target.classList.remove(className);
      }
    }
    [...document.querySelectorAll("[data-apartment-selector]")].filter(element => element.value === "").forEach(element => element.parentElement.remove());
    if (stairs.includes(event.target.value)) {
      // Create a new hidden div element
      let hiddenDiv = document.createElement("div");
      hiddenDiv.id = `hidden-stair-selection-${event.target.value}`;
      hiddenDiv.classList.add("hidden-stair-apartments-selection");
      apartmentSelect.classList.add(`hidden-stair-selection-${event.target.value}`);
      // console.log("added classname,", apartmentSelect)
      buildingSelect.parentElement.insertBefore(hiddenDiv, buildingSelect.nextSibling);
      var clonedApartmentSelect = apartmentSelect.cloneNode(true);
      clonedApartmentSelect.multiple = true;
      hiddenDiv.appendChild(clonedApartmentSelect);
      Array.from(clonedApartmentSelect.options).forEach(node => {
        if (node.dataset.stair && node.dataset.stair == event.target.value) {
          node.selected = true;
        } else {
          node.selected = false;
        }
      });
    }
  });
}

const bindConfirmationToSubmitButton = function() {
  const submitButton = document.querySelector("[data-fault-report-submit]");
  const allApartmentSelect = document.querySelector("[data-all-apartments-selector]");

  if (submitButton && allApartmentSelect) {
    submitButton.addEventListener('click', function(event) {
      if (document.getElementsByClassName("hidden-stair-apartments-selection").length > 0 || allApartmentSelect.checked) {
        if (!confirm("Huomaathan, että olet tekemässä vikailmoitusta kaikkiin asuntoihin tai kaikkiin tietyn rapun asuntoihin!")) {
          event.preventDefault();
        }
      }
    });
  }
}

const handleTaskCreate = () => {
  bindLocationSelect();
  bindMasterKeyCheckboxes();
  bindSpecialTaskCheckboxes();
  bindBuildingSelect();
  bindAllApartmentsSelection();
  initNewTaskImageUpload();
  bindConfirmationToSubmitButton();

  const faultReportAddTask = document.querySelector(
    "[data-fault-report-add-task]"
  );

  if (faultReportAddTask) {
    faultReportAddTask.addEventListener("click", event => {
      setTimeout(function() {
        bindRemoveTask();
        bindLocationSelect();
        bindMasterKeyCheckboxes();
        bindSpecialTaskCheckboxes();
      }, 10);
    });
  }
};

export default handleTaskCreate;
