const linkedTaskToggles = () => {
  const taskToggles = document.getElementById("toggle-linked-tasks");
  const tasks = document.getElementById("linked-tasks");

  if (tasks && taskToggles) {
    if (Number(tasks.dataset.linkedTasks) > 5) {
      // console.log("hide linked tasks");
      tasks.classList.add("hidden");
    }
  
    taskToggles.addEventListener("click", event => {
      if (tasks.classList.contains("hidden")) {
        // Open tasks list
        tasks.classList.remove("hidden");
      } else {
        // Close tasks list
        tasks.classList.add("hidden");
      }
    });
  }
}

export default linkedTaskToggles;
