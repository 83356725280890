import Choices from "choices.js";

const initMultiSelectInput = () => {
  const elements = document.querySelectorAll("[data-role='multi-select-input']");
  if (elements) {
    Array.from(elements).forEach(element => {
      const options = JSON.parse(element.dataset.choices);

      const texts = {};
      texts.placeholder = element.dataset.placeholder;
      texts.loadingText = element.dataset.loadingText;
      texts.noResultsText = element.dataset.noResultsText;
      texts.noChoicesText = element.dataset.noChoicesText;
      texts.itemSelectText = element.dataset.itemSelectText;

      const choices = new Choices(element, {
        choices: options,
        removeItems: true,
        removeItemButton: true,
        placeholder: true,
        placeholderValue: texts.placeholder,
        loadingText: texts.loadingText,
        noResultsText: texts.noResultsText,
        noChoicesText: texts.noChoicesText,
        itemSelectText: texts.itemSelectText
      });

      element.addEventListener('addItem', function(event) {
        let tagsInput = getTagsInput(event.target);
        tagsInput.closest(".tag-input").classList.add("tag-input--loading")
        let values = getTagsFromInput(tagsInput)
        values.push(event.detail.value);
        if (shouldSubmitForm(event.target)) {
          submitForm(event, values).then((response) => {
            if (response.error) {
              choices.removeActiveItems();
              choices.setValue(response.tags);
            }
            if (response.tags_as_string) {
              tagsInput.value = response.tags_as_string
            }
            tagsInput.closest(".tag-input").classList.remove("tag-input--loading")
          })
        } else {
          tagsInput.value = values.join(",");
        }
      }, false);

      element.addEventListener('removeItem', function(event) {
        let tagsInput = getTagsInput(event.target);
        tagsInput.closest(".tag-input").classList.add("tag-input--loading")
        let values = getTagsFromInput(tagsInput);
        values = values.filter(function(value){ return value != event.detail.value } );
        if (shouldSubmitForm(event.target)) {
          submitForm(event, values).then((response) => {
            if (response.tags) {
              choices.setValue(response.tags);
            }
            if (response.tags_as_string) {
              tagsInput.value = response.tags_as_string
            }
            tagsInput.closest(".tag-input").classList.remove("tag-input--loading")
          })
        } else {
          tagsInput.value = values.join(",");
        }
      }, false);
    });
  }
};

const submitForm = (event, values) => {
  let form = formFor(event.target);
  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  return fetch(form.action, {
    method: 'PUT',
    headers: headers,
    credentials: 'same-origin',
    body: JSON.stringify({ task: { new_tags: values.join(",")} })
  }).then((response) => {
    return response.json();
  })
}

const formFor = (element) => {
  return element.closest("form");
};

const shouldSubmitForm = (element) => {
  return formFor(element).dataset.remote === "true";
}

const getTagsFromInput = (element) => {
  return element.value.split(",").filter(function(value){ return value !== "" });
};

const getTagsInput = (target) => {
  return target.closest("[data-multi-select-wrapper]").querySelector("[data-hidden-tags-input]");
}

export default initMultiSelectInput;
