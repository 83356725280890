const taskActions = () => {
  const taskActions = document.querySelector("[data-task-actions]");
  const submitComment = document.querySelector("[data-task-comment-submit]");

  const commentClose = document.querySelector("[data-task-comment-close]");
  const quickStart = document.querySelector("[data-task-quick-start]");

  if (taskActions) {
    const commentTextarea = taskActions.querySelector("[data-task-comment]");
    const commentButton = taskActions.querySelector("[data-task-comment-open]");
    const commentPane = taskActions.querySelector("[data-task-comment-pane]");
    commentButton.addEventListener("click", event => {
      taskActions.classList.add("task-actions--commenting");
      commentPane.classList.add("task-actions__pane--active");

      ["keyup", "paste"].forEach(event => {
        commentTextarea.addEventListener(event, handler => {
          setTimeout(function() {
            if (commentTextarea.value.length > 0) {
              submitComment.disabled = false;
            } else {
              submitComment.disabled = true;
            }
          }, 0);
        });
      });
      if (quickStart) {
        quickStart.classList.add("hidden");
      }
      commentClose.addEventListener("click", event => {
        taskActions.classList.remove("task-actions--commenting");
        commentPane.classList.remove("task-actions__pane--active");
        if (quickStart) {
          quickStart.classList.remove("hidden");
        }
      });
    });

    const timeSelect = document.querySelector("[data-task-comment-time]");
    const timeExtra = document.querySelector("[data-task-comment-extra]");
    const statusSelect = document.querySelector("[data-task-comment-status]");
    const assignSelect = document.querySelector("[data-task-comment-assign]");

    if (timeSelect) {
      timeSelect.addEventListener("change", event => {
        handleSelectOptions(event.target, timeSelect, "time");
        if (event.target.value === "0") {
          timeExtra.classList.remove("hidden");
        } else {
          timeExtra.classList.add("hidden");
        }
      });
    }
    if (statusSelect) {
      statusSelect.addEventListener("change", event => {
        handleSelectOptions(event.target, statusSelect, "status");
      });
    }

    if (assignSelect) {
      assignSelect.addEventListener("change", event => {
        handleSelectOptions(event.target, assignSelect, "assign");
      });
    }

    const handleSelectOptions = (select, container, actionType) => {
      if (select.selectedIndex !== 0) {
        container.classList.add("task-actions__select--active");
      } else {
        container.classList.remove("task-actions__select--active");
      }
      changeSubmitText(actionType);
    };

    const changeSubmitText = text => {
      if (text === "status") {
        submitComment.innerHTML = submitComment.dataset.labelStatus;
        submitComment.classList.add("button--green");
      } else if (text === "assign") {
        submitComment.innerHTML = submitComment.dataset.labelAssign;
        submitComment.classList.remove("button--green");
      } else if (text === "time") {
        submitComment.innerHTML = submitComment.dataset.labelTime;
        submitComment.classList.remove("button--green");
      } else {
        submitComment.innerHTML = submitComment.dataset.labelComment;
        submitComment.classList.remove("button--green");
      }
    };

    const assignButton = document.querySelector("[data-task-assign]");
    const assignClose = document.querySelector("[data-task-assign-close]");
    const assignPane = document.querySelector("[data-task-assign-pane]");

    if (assignButton) {
      assignButton.addEventListener("click", event => {
        taskActions.classList.add("task-actions--assigning");
        assignPane.classList.add("task-actions__pane--active");
        if (quickStart) {
          quickStart.classList.add("hidden");
        }
        assignClose.addEventListener("click", event => {
          taskActions.classList.remove("task-actions--assigning");
          assignPane.classList.remove("task-actions__pane--active");
          if (quickStart) {
            quickStart.classList.remove("hidden");
          }
        });
      });
    }
  }
};

export default taskActions;
