import List from "list.js";

const initList = () => {
  const apartmentSearch = document.querySelector("#apartment-search");
  const apartmentSearchInput = document.querySelector(
    "[data-apartments-search]"
  );
  const apartmentList = document.querySelector("[data-apartments-list]");
  const buildingsList = document.querySelector("[data-buildings-list]");

  let showSearch = false;
  const showSearchResults = () => {
    showSearch = true;
    apartmentList.classList.remove("hidden");
    buildingsList.classList.add("hidden");
  };

  const hideSearchResults = () => {
    showSearch = false;
    apartmentList.classList.add("hidden");
    buildingsList.classList.remove("hidden");
  };

  if (apartmentSearch) {
    const options = {
      valueNames: ["apartment-address"]
    };

    const apartmentSearchList = new List("apartment-search", options);

    apartmentSearchInput.addEventListener("keyup", event => {
      if (event.target.value.length > 0) {
        if (!showSearch) {
          showSearchResults();
        }
      } else {
        hideSearchResults();
      }
    });
  }
};

export default initList;
