const initCheckboxFilterList = () => {
	const checkboxFilterLists = document.querySelectorAll(
		"[data-checkbox-filter-list]"
	);
	if (checkboxFilterLists) {
		//alert("Saved");

		Array.from(checkboxFilterLists).forEach((list) => {
			const mainToggle = list.querySelector(
				"[data-checkbox-filter-list-main] input[type='checkbox']"
			);
			const collectionContainer = list.querySelector(
				".checkbox-filter-list__collection"
			);
			const collectionCheckboxes = list.querySelectorAll(
				".checkbox-filter-list__collection input[type='checkbox']"
			);

			setMainCheckedState(
				mainToggle,
				collectionCheckboxes,
				collectionContainer
			);

			mainToggle.addEventListener("change", (event) => {
				const mainIsChecked = event.target.checked;
				Array.from(collectionCheckboxes).forEach((checkbox) => {
					if (mainIsChecked) {
						checkbox.checked = true;
						collectionContainer.classList.add(
							"checkbox-filter-list__collection--active"
						);
					} else {
						checkbox.checked = false;
						collectionContainer.classList.remove(
							"checkbox-filter-list__collection--active"
						);
					}
				});
			});
			Array.from(collectionCheckboxes).forEach((checkbox) => {
				checkbox.addEventListener("change", (event) => {
					setMainCheckedState(
						mainToggle,
						collectionCheckboxes,
						collectionContainer
					);
				});
			});
		});
	}
};

const setMainCheckedState = function (
	mainToggle,
	checkboxes,
	collectionContainer
) {
	if (Array.from(checkboxes).some((checkbox) => checkbox.checked)) {
		mainToggle.checked = true;
		collectionContainer.classList.add(
			"checkbox-filter-list__collection--active"
		);
	} else {
		mainToggle.checked = false;
		collectionContainer.classList.remove(
			"checkbox-filter-list__collection--active"
		);
	}
};

export default initCheckboxFilterList;
