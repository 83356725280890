const relatedtaskToggles = () => {
  const taskToggles = document.querySelectorAll("[data-related-tasks-toggle]");

  Array.from(taskToggles).forEach(taskToggle => {
    if (taskToggle) {
      taskToggle.addEventListener("click", event => {
        event.stopPropagation();

        let relatedTasksId = taskToggle.dataset.relatedTasksToggle;
        let relatedTasks = document.querySelector('[data-related-tasks="' + relatedTasksId + '"]');

        if (relatedTasks.classList.contains("task-card-related-tasks--close")) {
          // Open tasks list
          relatedTasks.classList.remove("task-card-related-tasks--close");
          relatedTasks.classList.add("task-card-related-tasks--open");
        } else {
          // Close tasks list
          relatedTasks.classList.remove("task-card-related-tasks--open");
          relatedTasks.classList.add("task-card-related-tasks--close");
        }
      });
    }
  });
};

export default relatedtaskToggles;
