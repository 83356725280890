const initSidepanels = () => {
  const panel = document.querySelector("[data-sidepanel]");
  if (panel) {
    const header = panel.querySelector("[data-sidepanel-header]");
    header.addEventListener("click", () => {
      if (panel.classList.contains("sidepanel--open")) {
        panel.classList.remove("sidepanel--open");
      } else {
        panel.classList.add("sidepanel--open");
      }
    });

    const inputs = panel.querySelectorAll("input, select");
    const submitButton = panel.querySelector("[data-sidepanel-submit]");
    Array.from(inputs).forEach(input => {
      input.addEventListener("change", function() {
        submitButton.disabled = false;
      });
    });
  }
};

export default initSidepanels;
