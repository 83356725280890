let areaSelectElement, buildingSelectElement, apartmentSelectElement;
let defaultBuildingOptionsHTML, defaultApartmentOptionsHTML;

const buildingOptions = {};
const apartmentOptions = {};

export default () => {
  areaSelectElement = document.querySelector('[data-search-filter-area]');
  buildingSelectElement = document.querySelector(
    '[data-search-filter-building]'
  );
  apartmentSelectElement = document.querySelector(
    '[data-search-filter-apartment]'
  );

  if (!areaSelectElement || !buildingSelectElement || !apartmentSelectElement) {
    return;
  }

  defaultBuildingOptionsHTML = buildingSelectElement.innerHTML;
  defaultApartmentOptionsHTML = apartmentSelectElement.innerHTML;

  initOptions(buildingSelectElement, buildingOptions);
  initOptions(apartmentSelectElement, apartmentOptions);

  if (areaSelectElement.value) {
    handleAreaSelectChange();
  }

  if (buildingSelectElement.value) {
    handleBuildingSelectChange();
  }

  areaSelectElement.onchange = () => handleAreaSelectChange();
  buildingSelectElement.onchange = () => handleBuildingSelectChange();
};

const initOptions = (selectElement, options) => {
  const prompt = selectElement.querySelectorAll('option')[0].outerHTML;
  options['blank'] = prompt;
  const optgroupElements = [...selectElement.querySelectorAll('optgroup')];
  optgroupElements.forEach(optgroupElement => {
    options[optgroupElement.label] = optgroupElement.innerHTML;
  });
};

const handleAreaSelectChange = () => {
  chooseOptgroupOptions(
    areaSelectElement,
    buildingSelectElement,
    buildingOptions,
    defaultBuildingOptionsHTML
  );
  chooseOptgroups(
    buildingSelectElement,
    apartmentSelectElement,
    apartmentOptions,
    defaultApartmentOptionsHTML
  );
};

const handleBuildingSelectChange = () => {
  chooseOptgroupOptions(
    buildingSelectElement,
    apartmentSelectElement,
    apartmentOptions,
    defaultApartmentOptionsHTML
  );
};

const chooseOptgroupOptions = (
  parentElement,
  targetElement,
  availableOptions,
  defaultHTML
) => {
  const label = parentElement.options[parentElement.selectedIndex].text;
  if (availableOptions[label]) {
    const prompt = availableOptions['blank'];
    targetElement.innerHTML = prompt + availableOptions[label];
  } else {
    targetElement.innerHTML = defaultHTML;
  }
};

const chooseOptgroups = (
  parentElement,
  targetElement,
  availableOptions,
  defaultHTML
) => {
  const labels = [...parentElement.querySelectorAll('option')].map(
    optionElement => optionElement.text
  );
  let html;

  labels.forEach(label => {
    if (availableOptions[label]) {
      html += `<optgroup label="${label}">${
        availableOptions[label]
      }</optgroup>`;
    }
  });

  if (html) {
    targetElement.innerHTML = availableOptions['blank'] + html;
  } else {
    targetElement.innerHTML = defaultHTML;
  }
};
