const siderbarToggle = () => {
  const menuToggle = document.querySelector("[data-sidebar-toggle]");
  const pageWrapper = document.querySelector("[data-page-wrapper]");
  const mainWrapper = document.querySelector("[data-main-wrapper]");

  if (menuToggle) {
    menuToggle.addEventListener("click", event => {
      event.stopPropagation();
      pageWrapper.classList.add("site-wrapper--sidebar-open");
      mainWrapper.addEventListener("click", event => {
        event.preventDefault();
        pageWrapper.classList.remove("site-wrapper--sidebar-open");
        mainWrapper.removeEventListener("click", this);
      });
    });
  }
};

export default siderbarToggle;
