const previewImage = function(input) {
  if (input.files && input.files[0]) {
    const parent = input.closest("[data-photo-upload]");
    const label = parent.querySelector("[data-photo-upload-label]");
    const preview = parent.querySelector("[data-photo-upload-preview]");
    const reader = new FileReader();

    reader.onload = function(e) {
      if (preview) {
        input
          .closest(".nested-fields")
          .querySelector("[data-photo-upload-preview]")
          .setAttribute("src", e.target.result);
        preview.classList.remove("hidden");
      }
      const value = input.value.replace(/^.*[\\\/]/, "");
      label.innerText = value;
    };

    reader.readAsDataURL(input.files[0]);
  }
};

export default previewImage;
